@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: #dddddd;
}

.parent {
  width: 100vw;
  height: 90vh;
  margin: 0;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-left: 20vw;
  padding-right: 20vw;
  overflow-x: hidden;
}

.header {
  background-color: #333333;
  height: 60px;
  flex: 1 1 auto;
  position: sticky;
  z-index: 100;
}

.header-user {
  position: absolute;
  right: 0px;
  width: 100vw;
  bottom: 0px;
  top: 0px;
  color: white;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;

  vertical-align: middle;
  line-height: 60px;
  margin-right: 10px;
}

.header-user > * {
  height: 100%;
}


.navbar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: 100%;

  padding-left: 20vw;
  padding-right: 20vw;
}

.navbarlink {
  flex: 1 1 0;
  border: none;
  background: none;
  color: white;
  font-size: 1.5rem;
  width: 100%;
  position: relative;
}

.navbarlink.active {
  color: #333333;
}

.navbarlink::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  height: 8px;
  left: 0;
  background-color: #ffc107;
  opacity: 100%;
  width: 100%;
  transform-origin: bottom middle;
  transition: transform 0.2s ease-in, height 0.1s ease-out;
  transform: scale(0, 1);
  z-index: -1;
}

.navbarlink:hover::before {
  transform: scale(1, 1);
}

.navbarlink.active::before {
  height: 100%;
  transform: scale(1, 1);
}

.parent > * {
  flex: 1 1 0px;
  height: 100%;
}

.card {
  border-radius: 10px;
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

.itemlist {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 20px;
  flex: 1 1 0;
  gap: 10px;
}

.itemlist-search {
  position: sticky;
  flex: 0 1 0;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  border: 2px solid black;
  font-size: 1.5rem;
}

.itemlist-list {
  flex: 1 1 1;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  overflow-y: auto;
}

.itemlist-list td,
th {
  text-align: left;
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.itemlist-list td,
th#checkbox {
  width: 5%;
}

.itemlist-list td {
  border-top: 1px solid lightgrey;
}

.item-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 1.5rem;
  overflow-y: auto;
  height: 100%;
  flex: 1 1 0;
}

.item-form input,
textarea {
  padding: 10px;
  resize: none;
  border-radius: 10px;
  font-size: 1.5rem;
  flex: 1 1 auto;
}

.item-form textarea {
  min-height: 100px;
}

#item-form-buttonrow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  flex: 1 1 auto;
}

#item-form-buttonrow button {
  flex: 1 1 0;
}

#item-form-buttonrow button {
  border-radius: 10px;
  padding: 10px;
  border: none;
  color: white;
  font-size: 1.5rem;
}

#item-form-submit {
  background-color: #28a745;
}

#item-form-cancel {
  background-color: #ff5252;
}

.pill-selector {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  min-height: 60px;
  gap: 20px;
}

.pill-selector-button {
  --pill-selector-background-color: #1976d2;
  --pill-selector-selected-font-color: white;
  --pill-selector-font-color: black;
  font-size: 1.5rem;
  border: none;
  flex: 1 1 0;
  padding: none;
  margin: none;
  border-radius: 10px;
  background-color: white;
  color: var(--pill-selector-font-color);
  transition: background-color 0.2s, color 0.2s;
}

.pill-selector-button:not(.selected-pill):hover {
  background-color: grey;
}

.pill-selector-button.selected-pill {
  background: var(--pill-selector-background-color);
  color: var(--pill-selector-selected-font-color);
}

.pictureform {
  position: relative;
  display: flex;
  min-height: 300px;
  max-height: 300px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 1 auto;
}

.pictureform video {
  flex: 1 1 0;
  width: 50%;
  height: 100%;
  border: 2px solid #1976d2;
}

.pictureform-options {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
  gap: 10px;
  padding: 10px;
}

.pictureform-options button {
  background-color: #1976d2;
  color: white;
  padding: 10px;
  font-size: 1rem;
}

.pictureform-image {
  flex: 1 1 0;
}

.pictureform-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 2px solid #1976d2;
}

.pictureform #pictureform-fileinput {
  display: none;
}

.picture-itemlist {
  margin-top: 20px;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.picture-itemlist-item {
  justify-self: stretch;
  position: relative;
  flex: 1 0 150px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  max-height: 150px;
  padding-bottom: 10px;
  border-bottom: 2px solid grey;
  line-height: 10px;
}

.itemimage {
  flex: 1 0 0;
  height: 100%;
  width: 100%;
  overflow:hidden;
}

.itemimage img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.picture-itemlist-item .iteminfo {
  width: 100%;
  flex: 3 0 0;
  height: 100%;
  padding-left: 10px;
  padding-bottom: 10px;
  overflow-x: hidden;
}

.picture-itemlist-item .iteminfo #itemName {
  font-size: 1.5rem;
}

.picture-itemlist-item .iteminfo a {
  text-decoration: none;
  text-overflow: clip;
}

.loginpage {
  position: relative;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 40px;
  margin-bottom: 50vh;
}

/*Divider*/
.loginpage::before {
  position: absolute;
  content: "";
  top: 5%;
  bottom: 5%;
  width: 1px;
  background-color: lightgrey;
  right: 50%;
}

@media only screen and (max-width: 1000px) {
  .loginpage {
    flex-direction: column;
  }

  .loginpage::before {
    content: initial;
  }
  .parent {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 60px;
  }

  .navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header-user {
    position: fixed;
    top: initial;
    bottom: 0px;
    right: 0px;
    left: 0px;
    height: 60px;
    background-color: black;
    font-size: 20px;
    z-index: 100;
    padding-right: 20px;
    width: 100%;
    background-color: #333333;
  }
}

@media only screen and (max-width: 400px) {
  * {
    font-size: 4vw;
  }
}

@media only screen and (max-height: 600px) and (max-width: 1000px) {
  .loginpage,
  .signuppage {
    margin-bottom: 10px;
    overflow-y: scroll;
    min-height: 100%;
  }
}

#loginform,
#signupform {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
}

#loginform input,
#signupform input {
  padding: 10px;
}

#loginform input,
#loginform button,
#signupform input,
#signupform button {
  flex: 0 1 50px;
}

#loginform button,
#signupform button {
  background-color: #1976d2;
  color: white;
}

#loginform p,
#signupform p {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.loginproviders {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;

  flex: 1 1 0px;
}

.iconbutton {
  width: 100%;
  height: 50px;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 1.5em;
  padding: 10px;
}

.account #credentials {
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.account #passwordchange {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.account #passwordchange > * {
  padding: 10px;
}

.account #submitpassword {
  background-color: #1976d2;
  color: white;
  padding: 10px;
}

.account {
  margin-top: 10px;
  padding: 30px;
  margin-bottom: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.signuppage {
  position: relative;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 40px;
  margin-bottom: 50vh;
}

.nomatch {
  color: lightcoral;
  border-color: lightcoral;
}

@keyframes alertBoxSlideInFromTop {
  0% {
    transform: translate(-50%, -300%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes alertBoxCollapse {
  0% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  100% {
    transform: translate(-50%, -50%) scale(0, 0);
  }
}

.alertbox {
  position: fixed;
  top: 40px;
  left: 50%;
  min-width: 300px;
  min-height: 60px;
  z-index: 1000;
  padding: 10px;
  border-radius: 5px;
  animation: 0.2s ease-out 0s 1 alertBoxSlideInFromTop,
    0.2s ease-out 5s 1 alertBoxCollapse;
  animation-fill-mode: forwards;
}

.alert-level-error {
  background-color: lightcoral;
  border: 3px solid crimson;
  color: black;
}

.alert-level-warning {
  background-color: gold;
  border: 3px solid GoldenRod;
  color: black;
}

.alert-level-info {
  background-color: DeepSkyBlue;
  border: 3px solid #1976d2;
  color: black;
}

.alert-level-success {
  background-color: lightgreen;
  border: 3px solid forestgreen;
  color: forestgreen;
}

.buttonrise {
  transition: transform 0.15s ease-out;
}

.buttonrise:hover {
  transform: translateY(-3%);
}

.table-actions {
  height: 70px;
}